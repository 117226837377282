.home {
    background: -moz-linear-gradient(338deg, rgba(17, 54, 115, 1) 33%, rgba(17, 54, 115, 1) 40%, rgba(26, 83, 172, 1) 93%, rgba(28, 87, 179, 1) 99%);
    /* ff3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(33%, rgba(17, 54, 115, 1)), color-stop(40%, rgba(17, 54, 115, 1)), color-stop(93%, rgba(26, 83, 172, 1)), color-stop(99%, rgba(28, 87, 179, 1)));
    /* safari4+,chrome */
    background: -webkit-linear-gradient(338deg, rgba(17, 54, 115, 1) 33%, rgba(17, 54, 115, 1) 40%, rgba(26, 83, 172, 1) 93%, rgba(28, 87, 179, 1) 99%);
    /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(338deg, rgba(17, 54, 115, 1) 33%, rgba(17, 54, 115, 1) 40%, rgba(26, 83, 172, 1) 93%, rgba(28, 87, 179, 1) 99%);
    /* opera 11.10+ */
    background: -ms-linear-gradient(338deg, rgba(17, 54, 115, 1) 33%, rgba(17, 54, 115, 1) 40%, rgba(26, 83, 172, 1) 93%, rgba(28, 87, 179, 1) 99%);
    /* ie10+ */
    background: linear-gradient(112deg, rgba(17, 54, 115, 1) 33%, rgba(17, 54, 115, 1) 40%, rgba(26, 83, 172, 1) 93%, rgba(28, 87, 179, 1) 99%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#113673', endColorstr='#1c57b3', GradientType=1);
    /* ie6-9 */
    position: relative;
    padding: 1px 0 0;
    min-height: 100vh;
    &:after {
        background-image: url("../../img/front/back-design.svg");
        background-repeat: no-repeat;
        content: "";
        height: 100%;
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 0;
        width: 360px;
    }
    .home-right-btn {
        position: absolute;
        right: 60px;
        top: 30px;
        z-index: 2;
        .btn-box {
            a {
                float: left;
                font-size: 18px;
                font-weight: 600;
                line-height: 20px;
                margin: 0 0 0 25px;
                position: relative;
                text-decoration: none;
                transition: all 300ms ease 0s;
                -webkit-transition: all 300ms ease 0s;
                -moz-transition: all 300ms ease 0s;
                text-transform: uppercase;
                &:hover {
                    opacity: 0.8;
                }
                &:after {
                    content: "";
                    position: absolute;
                }
                &.signup {
                    background-color: #ffffff;
                    border-radius: 5px 0 0 5px;
                    padding: 10px 10px 10px 15px;
                    color: $fwz_color_blue;
                    &:after {
                        background-image: url(../../img/front/arrow-white.png);
                        right: -15px;
                        top: 0;
                        width: 20px;
                        height: 100%;
                        background-size: 100% 100%;
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                    }
                }
                &.signin {
                    background-color: #499cde;
                    border-radius: 0 5px 5px 0;
                    padding: 10px 15px 10px 10px;
                    color: #fff;
                    &:after {
                        background-image: url(../../img/front/arrow-light-blue.png);
                        left: -15px;
                        top: 0;
                        width: 20px;
                        height: 100%;
                        background-size: 100% 100%;
                    }
                }
            }
            .log-reg-form {
                background-color: #ffffff;
                border: 1px solid #dadada;
                padding: 25px;
                position: absolute;
                display: none;
                right: 108px;
                text-align: center;
                top: 40px;
                width: 540px;
                h2 {
                    color: $fwz_color_blue;
                    font-size: 28px;
                    font-weight: 600;
                    margin: 0 0 30px 0;
                    position: relative;
                    &:after {
                        background-color: $fwz_bgcolor_blue;
                        bottom: -15px;
                        content: "";
                        height: 3px;
                        left: 0;
                        margin: auto;
                        position: absolute;
                        right: 0;
                        width: 30px;
                    }
                }
                h5 {
                    color: $fwz_color_grey;
                    font-size: 21px;
                    font-weight: 300;
                    line-height: 20px;
                    margin: 0;
                }
                p {
                    color: $fwz_color_grey;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 17px;
                    margin: 5px 0 0;
                    position: relative;
                    z-index: 1;
                }
                .form {
                    margin: 30px -7.5px 0 -7.5px;
                    .field {
                        float: left;
                        margin-bottom: 15px;
                        padding: 0 7.5px;
                        width: 50%;
                        &.wazirUrl {
                            padding: 0;
                            width: 100%;
                            .urlBox {
                                float: left;
                                padding: 0 7.5px;
                                width: 50%;
                                input {
                                    text-align: right;
                                }
                            }
                            label {
                                color: $fwz_color_blue;
                                float: left;
                                font-size: 18px;
                                line-height: 20px;
                                margin: 8px 0 0;
                                padding: 0 7.5px;
                                text-align: left;
                                width: 50%;
                            }
                        }
                        input {
                            border: 1px solid #dadada;
                            border-radius: 5px;
                            box-sizing: border-box;
                            font-size: 14px;
                            height: 38px;
                            color: #959595;
                            padding: 0 10px;
                            width: 100%;
                            &::placeholder {
                                color: #858585;
                            }
                        }
                    }
                    .butBox {
                        float: left;
                        padding: 0 7.5px;
                        width: 100%;
                        button {
                            background-color: $fwz_bgcolor_blue;
                            border: medium none;
                            border-radius: 5px;
                            transition: all 300ms ease 0s;
                            -webkit-transition: all 300ms ease 0s;
                            -moz-transition: all 300ms ease 0s;
                            color: #ffffff;
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 20px;
                            padding: 9px 10px;
                            text-transform: uppercase;
                            width: 100%;
                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }
                }
                &.signinBox {
                    right: 0;
                    width: 400px;
                    .form {
                        .field {
                            width: 100%;
                            .urlBox {
                                width: 60%;
                            }
                            label {
                                width: 40%;
                            }
                        }
                        .remember {
                            float: left;
                            margin-bottom: 10px;
                            padding: 0 7.5px;
                            text-align: left;
                            width: 100%;
                            .cus-checkobx {
                                position: relative;
                                i {
                                    color: $fwz_color_grey;
                                    font-size: 16px;
                                    font-style: normal;
                                    line-height: 16px;
                                    margin: 0 0 0 3px;
                                    vertical-align: top;
                                }
                            }
                            input {
                                display: inline-block;
                                margin: 2px 2px 0;
                                vertical-align: top;
                            }
                            input:checked~.checkmark:after {
                                display: block;
                            }
                            .checkmark {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 17px;
                                width: 17px;
                                border: 2px solid #929292;
                                background-color: #fff;
                                &:after {
                                    content: "";
                                    position: absolute;
                                    display: none;
                                    left: 4px;
                                    top: 2px;
                                    width: 3px;
                                    height: 6px;
                                    border: solid #929292;
                                    border-width: 0 2px 2px 0;
                                    -webkit-transform: rotate(45deg);
                                    -ms-transform: rotate(45deg);
                                    transform: rotate(45deg);
                                }
                            }
                        }
                        .workspace-url {
                            float: left;
                            margin: 20px 0 -10px;
                            position: relative;
                            width: 100%;
                            &:after {
                                background-color: #69bcfe;
                                bottom: -16px;
                                content: "";
                                left: -19px;
                                position: absolute;
                                right: -18px;
                                top: -5px;
                            }
                            p {
                                color: #fff;
                            }
                            a {
                                color: #113673;
                                display: inline-block;
                                float: none;
                                font-size: 16px;
                                margin: 6px 0 0;
                                text-decoration: underline;
                                text-transform: none;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
            .user-box {
                display: table-cell;
                vertical-align: middle;
                .user-img {
                    height: 40px;
                    display: table-cell;
                    width: 40px;
                    vertical-align: middle;
                    text-align: center;
                    border: solid 2px #fff;
                    border-radius: 50px;
                    position: relative;
                    overflow: hidden;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;
                    }
                }
                .user-name {
                    display: table-cell;
                    vertical-align: middle;
                    padding-left: 10px;
                    color: #fff;
                    text-transform: capitalize;
                }
            }
        }
        &.open-signup {
            .btn-box {
                .signup {
                    border-left: 1px solid #dadada;
                    border-radius: 0;
                    border-right: 1px solid #dadada;
                    border-top: 1px solid #dadada;
                    padding: 10px 15px;
                    z-index: 1;
                    &:after {
                        display: none;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
                .signin {
                    background-color: #499cde;
                    padding: 10px 15px;
                    color: #fff;
                    transition: 0ms;
                    margin: 0 0 0 20px;
                    border-radius: 0;
                    &:after {
                        display: none;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
                .signupBox {
                    display: block;
                }
            }
        }
        &.open-signin {
            .btn-box {
                .signin {
                    background-color: #fff;
                    color: $fwz_color_blue;
                    border-left: 1px solid #dadada;
                    border-radius: 0;
                    margin: 0 0 0 20px;
                    border-right: 1px solid #dadada;
                    border-top: 1px solid #dadada;
                    padding: 10px 15px;
                    z-index: 1;
                    &:after {
                        display: none;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
                .signup {
                    background-color: #499cde;
                    padding: 10px 15px;
                    color: #fff;
                    transition: 0ms;
                    margin: 0 0 0 20px;
                    border-radius: 0;
                    &:after {
                        display: none;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
                .signinBox {
                    display: block;
                }
            }
        }
    }
    .infographicsImg {
        height: 55%;
        position: absolute;
        right: 9%;
        text-align: right;
        top: 54%;
        transform: translate(0px, -50%);
        -webkit-transform: translate(0px, -50%);
        -moz-transform: translate(0px, -50%);
        width: auto;
        z-index: 1;
        img {
            height: 100%;
        }
    }
    .timer-matter {
        bottom: 25px;
        opacity: 0.2;
        position: absolute;
        right: 60px;
        text-align: center;
        .txt-lbl {
            color: #ffffff;
            display: block;
            font-size: 26px;
            font-weight: 700;
            line-height: 29px;
            text-transform: uppercase;
        }
        label {
            color: #ffffff;
            display: block;
            font-size: 80px;
            font-weight: 700;
            line-height: 70px;
            text-align: center;
        }
    }
    .head {
        margin-top: 100px;
        padding-left: 20px;
        position: relative;
        &:after {
            background-color: $fwz_bgcolor_orange;
            bottom: 5px;
            content: "";
            left: 0;
            position: absolute;
            top: 5px;
            width: 5px;
        }
        span {
            color: $fwz_color_orange;
            display: block;
            font-size: 30px;
            font-weight: 600;
            line-height: 30px;
            text-transform: uppercase;
        }
        label {
            color: #fff;
            display: block;
            font-size: 40px;
            font-weight: 300;
            line-height: 40px;
            text-transform: uppercase;
        }
        &.second {
            margin-top: 35px;
        }
    }
    .easy-eff {
        margin: 40px 0 0px;
        max-width: 100%;
        position: relative;
        width: 1000px;
        z-index: 1;
        h2 {
            color: #ffffff;
            font-weight: 700;
            font-size: 70px;
            line-height: 65px;
            margin: 0;
            text-transform: uppercase;
            .ti-cursor {
                height: 60px;
                overflow: hidden;
                display: inline-block !important;
                vertical-align: top;
                left: 10px;
            }
        }
        p {
            color: #a3c3fb;
            font-size: 17px;
            line-height: 23px;
            margin: 20px 0 0;
            max-width: 100%;
            width: 820px;
        }
        .list-box {
            margin: 50px -15px 0 -15px;
            overflow: hidden;
            .list {
                float: left;
                padding: 0 15px;
                margin-bottom: 25px;
                width: 25%;
                .imgbBox {
                    float: left;
                }
                label {
                    color: #ffffff;
                    display: block;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    overflow: hidden;
                    padding: 0 0 0 20px;
                }
            }
        }
    }
    .email-form {
        max-width: 100%;
        position: relative;
        width: 680px;
        z-index: 1;
        p {
            color: #fff;
            font-size: 17px;
            line-height: 23px;
            margin: 20px 0 15px;
        }
        .emailBox {
            padding-right: 220px;
            position: relative;
            input {
                background-color: #2b508d;
                border: 1px solid #ffffff;
                border-radius: 5px;
                color: #fff;
                font-size: 16px;
                height: 45px;
                padding: 0 15px;
                width: 100%;
                &::placeholder {
                    color: #a3c3fb;
                }
            }
            button {
                background-color: $fwz_bgcolor_orange;
                border: medium none;
                border-radius: 5px;
                color: $fwz_color_blue;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;
                height: 45px;
                padding: 0 15px;
                position: absolute;
                transition: all 300ms ease 0s;
                -webkit-transition: all 300ms ease 0s;
                -moz-transition: all 300ms ease 0s;
                right: 0;
                top: 0;
                text-transform: uppercase;
                width: 200px;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
    .logo-slider {
        margin-top: 20px;
        width: 1000px;
        max-width: 100%;
        .item {
            width: 185px;
            background-color: #fff;
            height: 120px;
            margin-right: 15px;
            position: relative;
            border-radius: 10px;
            img {
                max-height: 85%;
                max-width: 85%;
                position: absolute;
                width: auto;
                margin: auto;
                left: -999px;
                top: -999px;
                bottom: -999px;
                right: -999px;
            }
        }
    }
    .journy-btn {
        padding: 20px 0 20px 0;
        a {
            color: $fwz_color_orange;
            font-size: 22px;
            font-weight: 700;
            transition: all 300ms ease 0s;
            -webkit-transition: all 300ms ease 0s;
            -moz-transition: all 300ms ease 0s;
            position: relative;
            &:hover {
                opacity: 0.8;
            }
            &:after {
                background-image: url("../../img/front/arrow.svg");
                background-repeat: no-repeat;
                content: "";
                height: 20px;
                position: absolute;
                right: -33px;
                top: 10px;
                width: 20px;
            }
        }
    }
}

@media (max-width: 1600px) {
    .home {
        .infographicsImg {
            height: 50%;
            right: 5%;
        }
        .timer-matter {
            span {
                font-size: 24px;
                line-height: 25px;
            }
            label {
                font-size: 70px;
                line-height: 60px;
            }
        }
        .head {
            span {
                font-size: 26px;
                line-height: 26px;
            }
            label {
                font-size: 36px;
                line-height: 36px;
            }
        }
        .easy-eff {
            h2 {
                font-size: 60px;
                line-height: 55px;
            }
        }
    }
}

@media (max-width: 1260px) {
    .home {
        .infographicsImg {
            opacity: 0.5;
        }
    }
}

@media (max-width: 991px) {
    .home {
        .home-right-btn {
            right: 30px;
            .btn-box {
                a {
                    font-size: 14px;
                    line-height: 14px;
                }
                .log-reg-form {
                    width: 500px;
                    right: 95px;
                    top: 34px;
                }
            }
        }
        .timer-matter {
            right: 30px;
            .txt-lbl {
                font-size: 22px;
                line-height: 24px;
            }
            label {
                font-size: 50px;
                line-height: 50px;
            }
        }
        .head {
            label {
                font-size: 32px;
            }
        }
        .logo-slider {
            .item {
                width: 175px;
                height: 110px;
            }
        }
        .easy-eff {
            h2 {
                font-size: 42px;
            }
            p {
                font-size: 16px;
                line-height: 21px;
            }
            .list-box {
                .list {
                    .imgbBox {
                        img {
                            max-height: 35px;
                            max-width: 35px;
                        }
                    }
                    label {
                        font-size: 14px;
                        line-height: 17px;
                        padding: 0 0 0 15px;
                    }
                }
            }
        }
        .email-form {
            .emailBox {
                input {
                    height: 42px;
                }
                button {
                    height: 42px;
                    font-size: 16px;
                }
            }
        }
        .journy-btn {
            a {
                font-size: 20px;
            }
        }
    }
}

/*-- Wazir logo triangle animation --*/

.main-wrap {
    margin: 0 0 0 0;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 10px;
    left: -30px;
}

.wrap {
    width: 150px;
    height: 130px;
    margin: 30% auto;
    text-align: center;
    position: absolute;
}

.wrap:nth-child(1) {
    transform: scale(0.2);
    top: -130px;
    left: 0;
}

.wrap:nth-child(2) {
    transform: scale(0.2);
    top: -50px;
    left: -60px;
}

.wrap:nth-child(3) {
    transform: scale(0.35);
    top: 60px;
    left: -20px;
}

.wrap:nth-child(4) {
    transform: scale(0.40);
    top: 180px;
    left: -70px;
}

.wrap:nth-child(5) {
    transform: scale(0.4);
    top: -120px;
    left: auto;
    right: -50px;
}

.wrap:nth-child(6) {
    transform: scale(0.3);
    top: 10px;
    left: auto;
    right: -90px;
}

.wrap:nth-child(7) {
    transform: scale(0.4);
    top: 150px;
    left: auto;
    right: -90px;
}

.triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 75px solid transparent;
    border-right: 75px solid transparent;
    border-bottom: 130px solid #567aae;
    transform-origin: 75px 0;
    perspective-origin: 50% 50%;
    opacity: 0.7
}

.face-1 {
    transform-origin: 75px 0;
    transform: rotateY(0deg) rotateX(19.5deg);
    border-bottom-color: #5375a7
}

.face-2 {
    transform-origin: 75px 0;
    transform: rotateY(120deg) rotateX(19.5deg);
    border-bottom-color: #6a89ba
}

.face-3 {
    transform-origin: 75px 0;
    transform: rotateY(240deg) rotateX(19.5deg);
    border-bottom-color: #416295
}

.face-4 {
    transform-origin: 0 0px;
    transform: rotateX(90deg) translateY(-87px) translateZ(-122px);
    border-bottom-color: #2c59a0
}

.rotor-x {
    width: 150px;
    height: 130px;
    transform-origin: 75px 65px;
    transform-style: preserve-3d;
    animation: spinX 6s infinite linear;
}

.rotor-y {
    width: 150px;
    height: 130px;
    transform-origin: 75px 65px;
    transform-style: preserve-3d;
    animation: spinY 12s infinite linear;
}

.rotor-z {
    width: 150px;
    height: 130px;
    transform-origin: 75px 65px;
    transform-style: preserve-3d;
    animation: spinZ 18s infinite linear;
}

@keyframes spinX {
    from {
        transform: rotateX(0);
    }
    to {
        transform: rotateX(360deg);
    }
}

@keyframes spinY {
    from {
        transform: rotateY(0);
    }
    to {
        transform: rotateY(360deg);
    }
}

@keyframes spinZ {
    from {
        transform: rotateZ(0);
    }
    to {
        transform: rotateZ(360deg);
    }
}

@keyframes color1 {
    0% {
        border-bottom-color: #6b8cc0;
    }
    50% {
        border-bottom-color: #37537e;
    }
    100% {
        border-bottom-color: #233b5f;
    }
}

.wrap:nth-child(1) .rotor-x,
.wrap:nth-child(1) .rotor-y,
.wrap:nth-child(1) .rotor-z {
    animation-delay: 0.5s
}

.wrap:nth-child(2) .rotor-x,
.wrap:nth-child(2) .rotor-y,
.wrap:nth-child(2) .rotor-z {
    animation-delay: 0.8s
}

.wrap:nth-child(3) .rotor-x,
.wrap:nth-child(3) .rotor-z {
    animation-delay: 1s
}

.wrap:nth-child(4) .rotor-x,
.wrap:nth-child(4) .rotor-z {
    animation-delay: 1.5s
}

.wrap:nth-child(4) .rotor-y {
    animation-delay: 0.5s
}

.wrap:nth-child(5) .rotor-y,
.wrap:nth-child(5) .rotor-z {
    animation-delay: 1.5s
}

.wrap:nth-child(5) .rotor-x {
    animation-delay: 0.5s
}

.wrap:nth-child(7) .rotor-y,
.wrap:nth-child(7) .rotor-z {
    animation-delay: 0.7s
}

.wrap:nth-child(7) .rotor-x {
    animation-delay: 1s
}