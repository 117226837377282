body{
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    *{
        box-sizing: border-box;
        font-family: 'Source Sans Pro', sans-serif;
        outline: none;
    }

}
.container{
    padding-left: 150px;
    padding-right: 15px;
}

.page-content{
    background: -moz-linear-gradient(338deg, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 40%, rgba(246,246,246,1) 93%, rgba(246,246,246,1) 99%); /* ff3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(33%, rgba(255,255,255,1)), color-stop(40%, rgba(255,255,255,1)), color-stop(93%, rgba(246,246,246,1)), color-stop(99%, rgba(246,246,246,1))); /* safari4+,chrome */
    background: -webkit-linear-gradient(338deg, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 40%, rgba(246,246,246,1) 93%, rgba(246,246,246,1) 99%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(338deg, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 40%, rgba(246,246,246,1) 93%, rgba(246,246,246,1) 99%); /* opera 11.10+ */
    background: -ms-linear-gradient(338deg, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 40%, rgba(246,246,246,1) 93%, rgba(246,246,246,1) 99%); /* ie10+ */
    background: linear-gradient(112deg, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 40%, rgba(246,246,246,1) 93%, rgba(246,246,246,1) 99%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=1 ); /* ie6-9 */
    position: relative;
    overflow: hidden;
    padding: 1px 0 0;
    min-height: 100vh;
}
/******** sidebar ********/

.sidebar{
    bottom: 0;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 999;
    &:before{
        transition: all 300ms ease 0s;
        -webkit-transition: all 300ms ease 0s;
        -moz-transition: all 300ms ease 0s;
        background-color: rgba(5, 15, 32, 0.7);
        content: "";
        position: fixed;
        opacity: 0;
        visibility: hidden;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
    }
    .left-penal {
        background-color: $fwz_bgcolor_blue;
        bottom: 0;
        left: 0;
        overflow: auto;
        position: fixed;
        text-align: center;
        top: 0;
        width: 90px;
        z-index: 2;
        .logo {
            margin: 20px 0 0;
        }
        .menu-togle {
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translate(0px, -50%);
            -webkit-transform: translate(0px, -50%);
            -moz-transform: translate(0px, -50%); 
            width: 100%;
            a{
                display: inline-block;
                span{
                    background-color: #ffffff;
                    transition: all 300ms ease 0s;
                    -webkit-transition: all 300ms ease 0s;
                    -moz-transition: all 300ms ease 0s;
                    border-radius: 5px;
                    display: block;
                    height: 3px;
                    position: relative;
                    top: 0;
                    left: 0;
                    margin: 5px 0;
                    &:nth-child(1){
                        width: 33px;
                    }
                    &:nth-child(2){
                        width: 25px;
                    }
                    &:nth-child(3){
                        width: 20px;
                    }
                }
                &:hover{
                    span{
                        &:nth-child(1){
                            left: 12px;
                            top: 1px;
                            transform: rotate(34deg);
                            -webkit-transform: rotate(34deg);
                            -moz-transform: rotate(34deg);
                            width: 22px;
                        }
                        &:nth-child(2){
                            width: 33px;
                        }
                        &:nth-child(3){
                            left: 11px;
                            top: -1px;
                            transform: rotate(-34deg);
                            -webkit-transform: rotate(-34deg);
                            -moz-transform: rotate(-34deg);
                            width: 22px;
                        }
                    } 
                }
                &.open-menu{
                    span{
                        &:nth-child(1){
                            left: 0;
                            top: 8px;
                            transform: rotate(45deg);
                            -webkit-transform: rotate(45deg);
                            -moz-transform: rotate(45deg);
                            width: 33px;
                        }
                        &:nth-child(2){
                            opacity: 0;
                        }
                        &:nth-child(3){
                            left: 0;
                            top: -8px;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            width: 33px;
                        }
                    } 
                    &:hover{
                        span{
                            &:nth-child(1){
                                left: 0;
                                top: 1px;
                                transform: rotate(-34deg);
                                -webkit-transform: rotate(-34deg);
                                -moz-transform: rotate(-34deg);
                                width: 22px;
                            }
                            &:nth-child(2){
                                width: 33px;
                                opacity: 1;
                            }
                            &:nth-child(3){
                                left: 0px;
                                top: -1px;
                                transform: rotate(34deg);
                                -webkit-transform: rotate(34deg);
                                -moz-transform: rotate(34deg);
                                width: 22px;
                            }
                        } 
                    }
                }
            }
        }
        .social-link {
            bottom: 20px;
            position: absolute;
            text-align: center;
            width: 100%;
            a{
                display: block;
                height: 38px;
                transition: all 200ms ease 0s;
                -webkit-transition: all 200ms ease 0s;
                -moz-transition: all 200ms ease 0s;
                margin: 10px auto 0;
                width: 38px;
                &:hover{
                    transform: scale(1.1);
                    -webkit-transform: scale(1.1);
                    -moz-transform: scale(1.1);
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    .menu{
        bottom: 0;
        position: fixed;
        top: 0;
        width: 0;
        z-index: 1;

        .menu-inner{
            background-color: #0f2d5f;
            border-right: 2px solid #638ec2;
            bottom: 0;
            left: -143px;
            transition: all 300ms ease 0s;
            -webkit-transition: all 300ms ease 0s;
            -moz-transition: all 300ms ease 0s;
            position: absolute;
            top: 0;
            width: 235px;
            ul{
                margin: 0;
                padding: 0 0 0 35px;
                position: absolute;
                top: 50%;
                transform: translate(0px, -50%);
                -webkit-transform: translate(0px, -50%);
                -moz-transform: translate(0px, -50%);
                li{
                    display: block;
                    margin: 20px 0;
                    a{
                        color: #ffffff;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 25px;
                        text-decoration: none;
                        transition: all 300ms ease 0s;
                        -webkit-transition: all 300ms ease 0s;
                        -moz-transition: all 300ms ease 0s;
                        text-transform: uppercase;
                        &:hover{
                            color: #6288ca;
                        }
                    }
                }
            }
            .other-link {
                bottom: 0;
                left: 0;
                padding: 7px 10px;
                position: absolute;
                text-align: center;
                width: 100%;
                span {
                    border-bottom: 1px dashed;
                    color: #fff;
                    display: block;
                    margin-bottom: 5px;
                    padding-bottom: 5px;
                    font-size: 16px;
                    line-height: 19px;
                }
                a {
                    color: #fff;
                    display: inline-block;
                    font-size: 13px;
                    margin: 0 8px;
                    text-decoration: none;
                    position: relative;
                    transition: all 300ms ease 0s;
                    -webkit-transition: all 300ms ease 0s;
                    -moz-transition: all 300ms ease 0s;
                    &:hover{
                        color: #6288ca;
                    }
                    &:last-child{
                        &:after{
                            content: "";
                            position: absolute;
                            height: 13px;
                            width: 1px;
                            left: -9px;
                            top: 3px;
                            display: block;
                            background: #fff;
                        }
                    }
                }
            }
        }
    }
    &.open-menu{
        width: 100%;
        &:before{
            opacity: 1;
            visibility: visible;
        }
        .menu-inner{
            border-right: 2px solid #fff;
            left:90px;
        }
    }
}

/******** sidebar ********/

/******** Breadcrumb ********/

.breadcrumb{
    margin-top: 25px;
    overflow: hidden;
    .home-btn{
        float: left;
        a{
            background-color: $fwz_bgcolor_blue;
            background-image: url("../../img/front/home.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 22px auto;
            border-radius: 5px 0 0 5px;
            display: block;
            height: 40px;
            width: 45px;
        }
    }
    .page-breadcrumb{
        background: -moz-linear-gradient(90deg, #eaeaea -30%, #fff 80%, #fff 60%); /* ff3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(234,234,234,1)), color-stop(3%, rgba(234,234,234,1)), color-stop(100%, rgba(246,246,246,1))); /* safari4+,chrome */
        background: -webkit-linear-gradient(90deg, #eaeaea -30%, #fff 80%, #fff 60%); /* safari5.1+,chrome10+ */
        background: -o-linear-gradient(90deg, #eaeaea -30%, #fff 80%, #fff 60%); /* opera 11.10+ */
        background: -ms-linear-gradient(90deg, #eaeaea -30%, #fff 80%, #fff 60%); /* ie10+ */
        background: linear-gradient(90deg, #eaeaea -30%, #fff 80%, #fff 60%); /* w3c */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaeaea', endColorstr='#ffffff',GradientType=1 ); /* ie6-9 */
        float: left;
        width: 50%;
        ul{
            margin: 0;
            padding: 0 0 0 10px;
            li{
                color: #727272;
                display: inline-block;
                font-size: 16px;
                font-weight: 600;
                line-height: 40px;
                margin: 0 7px;
                position: relative;
                &:after{
                    content: "/";
                    position: absolute;
                    right: -11px;
                    top: 0;
                }
                &:last-child{
                    color: $fwz_color_blue;
                    &:after{
                        display: none;
                    }
                }
                a{
                    color: #727272;
                    text-decoration: none;
                }
            }
        }
    } 
}

/******** Breadcrumb ********/

/******** Page button ********/

.next-prev-btn{
    position: absolute;
    right: 60px;
    top: 25px;
    z-index: 1;
    .btn-box{
        a{
            background-color: $fwz_bgcolor_blue;
            float: left;
            font-size: 18px;
            font-weight: 600;
            color: #fff;
            line-height: 20px;
            position: relative;
            text-decoration: none;
            transition: all 300ms ease 0s;
            -webkit-transition: all 300ms ease 0s;
            -moz-transition: all 300ms ease 0s;
            text-transform: uppercase;
            &:first-child{
                margin: 0 25px 0 0;
            }
            &:hover{
                opacity: 0.8;
            }
            &:after{
                content: "";
                position: absolute;
            }
            &.previous{
                border-radius: 5px 0 0 5px;
                padding: 10px 10px 10px 35px;
                &:after{
                    background-image: url(../../img/front/arrow-blue.png);
                    right: -15px;
                    top: 0;
                    width: 20px;
                    height: 100%;
                    background-size: 100% 100%;
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                }
                &:before{
                    background-image: url("../../img/front/white-arrow.svg");
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 100% auto;
                    content: "";
                    height: 17px;
                    left: 10px;
                    position: absolute;
                    top: 12px;
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    width: 17px;
                }
            }
            &.next{
                border-radius: 0 5px 5px 0;
                padding: 10px 35px 10px 10px;
                &:after{
                    background-image: url(../../img/front/arrow-blue.png);
                    left: -15px;
                    top: 0;
                    width: 20px;
                    height: 100%;
                    background-size: 100% 100%;
                }
                &:before{
                    background-image: url("../../img/front/white-arrow.svg");
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 100% auto;
                    content: "";
                    height: 17px;
                    right: 10px;
                    position: absolute;
                    top: 12px;
                    width: 17px;
                }
            }
        }
    }
}

/******** Page button ********/

/******** Heading ********/

.head{
    margin-top: 45px;
    padding-left: 20px;
    position: relative;
    &:after{
        background-color: $fwz_bgcolor_orange;
        bottom: 5px;
        content: "";
        left: 0;
        position: absolute;
        top: 5px;
        width: 5px;
    }
    span{
        color: $fwz_color_orange; 
        display: block;
        font-size: 26px;
        font-weight: 700;
        line-height: 26px;
        text-transform: uppercase;
    }
    label{
        color: $fwz_color_blue;
        display: block;
        font-size: 50px;
        font-weight: 600;
        line-height: 50px;
        text-transform: uppercase;
    }
}

/******** Heading ********/

/******** Page label ********/

.page-lbl {
    color: #f0f0f0;
    font-size: 200px;
    font-weight: 800;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 180px;
    .type-txt{
        float: right;
    }
}

/******** Page label ********/

/******** Popup ********/

.popup {
    width:100%;
    height:100%;
    display:none;
    overflow: auto;
    position:fixed;
    top:0px;
    left:0px;
    background:rgba(0,0,0,0.6);
    z-index: 999;
    .popup-inner {
        left: 150px;
        margin-top: 75px;
        margin-bottom: 20px;
        max-width:700px;
        width:90%;
        padding:40px;
        position:relative;
        box-shadow:0px 2px 6px rgba(0,0,0,1);
        border-radius:3px;
        background:#fff;
        right: auto;
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms--moz-transform: scale(0);
        transform-origin: left top;
        -webkit-transform-origin: left top;
        -moz-transform-origin: left top;
        -ms-transform-origin: left top;
        transition: all 250ms ease 0s;
        -webkit-transition: all 250ms ease 0s;
        -moz-transition: all 250ms ease 0s;
        -ms-transition: all 250ms ease 0s;

    }
    &.open-popup{
        .popup-inner{
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms--moz-transform: scale(1);
        }
    }
}

/* Close Button */
.popup-close {
    width:30px;
    height:30px;
    padding-top:4px;
    display:inline-block;
    position:absolute;
    top:0px;
    right:0px;
    text-decoration: none;
    transition:ease 0.25s all;
    -webkit-transform:translate(50%, -50%);
    transform:translate(50%, -50%);
    border-radius:1000px;
    background:rgba(0,0,0,0.8);
    font-family:Arial, Sans-Serif;
    font-size:20px;
    text-align:center;
    line-height:100%;
    color:#fff;
    z-index: 1;
}
.popup-close:hover {
    -webkit-transform:translate(50%, -50%) rotate(180deg);
    transform:translate(50%, -50%) rotate(180deg);
    background:rgba(0,0,0,1);
    text-decoration:none;
}

/******** Popup ********/

@media screen and (max-width: 1620px) and (min-width: 1260px) {

    .scale-page{
        transform-origin: 0 0;
        width: 1620px;    
        overflow-x: hidden;
        height: auto !important;
    }
    .stopscroll {
        overflow: hidden !important;
        position: relative;
        z-index: 1;
    }
}

@media (max-width: 1600px) {

    .head {
        span {
            font-size: 24px;
            line-height: 24px;
        }
        label {
            font-size: 42px;
            line-height: 42px;
        }
    }
    .page-lbl{
        font-size: 150px;
    }
}

@media (max-width: 1260px) {

    .container {
        padding-left: 130px;
    }
    .next-prev-btn{
        right: 20px;
    }
    .popup{
        .popup-inner{
            left: 0;
            right: 0;
            margin: 30px auto;
        }
    }
}

@media (max-width: 1024px) {

    .page-lbl{
        display: none;
    }

    .head {
        span {
            font-size: 22px;
            line-height: 22px;
        }
        label {
            font-size: 36px;
            line-height: 36px;
        }
    }
    .breadcrumb {
        .home-btn{
            a{
                background-size: 16px auto;
                height: 35px;
                width: 40px;
            }
        }
        .page-breadcrumb {
            width: 80%;
            ul {
                li{
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
    .next-prev-btn {
        .btn-box {
            a{
                font-size: 14px;
                line-height: 14px;
                &.previous{
                    padding: 10px 10px 10px 30px;
                    &::before{
                        height: 10px;
                        top: 13px;
                        width: 12px;
                    }
                } 
                &.next{
                    padding: 10px 30px 10px 10px;
                    &::before{
                        height: 10px;
                        top: 13px;
                        width: 12px;
                    }
                } 
            }
        }
    }

}
@media (max-width: 767px) {
    .breadcrumb{
        margin-top: 70px;
    }
}






